import React from "react";
import menuIcon from "../../../Images/hamburger-menu.svg"

const Hamburger = ({className, toggleDropdown}) => {

    return <button className={className} onClick={toggleDropdown}>
        <img src={menuIcon} className="h-8" />
    </button>
}

export default Hamburger