import React from "react";
import ContactDetails from "./ContactDetails";
import Map from "./Map";
import Logo from "../../Images/Techsico_PMS185_PMS293_Tagline.svg"
import ContactInfo from "../../Content/ContactInfo.json"

const ContactSection = () => {
    return<section id="contact" className=" bg-slate-200 ">
        <div className=" flex flex-col md:flex-row justify-center gap-8 md:gap-24 justify-items-center items-center">
            <img src={Logo} className="h-16 mt-12 md:mt-0 "></img>
            <div className="flex flex-row md:flex-col flex-wrap gap-4 justify-center justify-items-center md:justify-items-start text-center md:text-left">
                <ContactDetails Title="Contact us" Content={[ContactInfo.phone, ContactInfo.email, "We reply within 24 hours"]} className="md:pt-16 "/>
                <ContactDetails Title="Business hours" Content={[ContactInfo.businessDays.text + ", " + ContactInfo.businessHours.text + ContactInfo.timezone.text]} className="md:py-2 "/>
                <ContactDetails Title="Main headquarters" Content={[ContactInfo.hqAddress]} className="md:pt-2 "/>
            </div>
        </div>
        <Map className="flex flex-col h-min justify-start items-center text-center pb-4"/>
    </section>
}

export default ContactSection