import React from "react";
import SectionHeader from "../SectionHeader";
import FacebookPageEmbed from "./FacebookPageEmbed";
import TwitterPageEmbed from "./TwitterPageEmbed";
import classNames from "../utilities/classNames";

const SocialSection = ({isVisible}) => {
    if(isVisible == "true"){
    return <div className={classNames ("h-fit bg-white")}>
        <SectionHeader className="bg-tech-blue  " Text="What we are up to" />
        
        
        <div className="flex flex-row flex-wrap justify-around items-center min-h-[500px] py-12 gap-y-2 overflow-ellipsis  ">
            <FacebookPageEmbed width="425" height="400"/>
            <TwitterPageEmbed width="425" height="400"/>
        </div>
    </div>   
    }
}

export default SocialSection