import React from "react";

const Job = ({location, URL}) => {
    return <a href={URL} className="hover:text-slate-300"   >
        <div className="border-4 border-white border-opacity-25 rounded-full job flex-none grow-0 h-36 w-36 flex flex-col items-center justify-between ">
        <div className="flex flex-col h-full justify-end p-1 w-3/4 flex-grow basis-0 ">{location}</div>
        <div  className=" border-b-2 border-tech-red h-1 w-3/4"></div>
        <span className=" flex flex-col justify-start p-1 mt-auto w-3/4  rounded-lg flex-grow basis-0" >Apply now</span>
    </div>
    </a>
}

export default Job;
