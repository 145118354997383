import React from "react";
import classNames from "../utilities/classNames";
import Paragraph from "./Paragraph";

const AboutDiv = ({Data, className}) =>{
    return <div className={classNames("w-full md:w-4/5 p-2.5 z-10", className)}>
        <h1 className=" font-black uppercase text-tech-red text-lg pb-2 md:pb-0">{Data.Title}</h1>
        {(Data.Content).map((item, index )=> <Paragraph key={index} Content={item} className="leading-loose" />)}
    </div>
}

export default AboutDiv