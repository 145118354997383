import React from 'react';
import LogoName from "../../Images/Techsico_PMS185_PMS293_NoTag.svg"
import Logo from "../../Images/Techsico_TMarkOnly_All.svg"

const Title = ({className}) =>{
    return <a href="#" className={className}>
        <img src={LogoName} className="h-8 hidden md:inline"/>
        <img src={Logo} className=" h-8 inline md:hidden" />
    </a>
}

export default Title