import React from "react";
import SectionHeader from "../SectionHeader";
import Solution from "./Solution";
import SolutionData from "../../Content/SolutionSection.json"

const SolutionSection = () =>{
    return<section id="solutions" className="bg-white ">
        <SectionHeader Text="Services" className={"bg-tech-blue"}/>
        <div className="flex flex-row flex-wrap h-min justify-center text-center relative md:[&>div:nth-child(odd)]:bg-inherit [&>div:nth-child(odd)]:bg-slate-100 ">
            {SolutionData.map((item, index )=> {
                if (item.imgURL != "" ){
                return <Solution key={index} imageName={item.imgURL} altText={item.altText} text={item.Content} className={"md:basis-1/3 flex flex-col flex-wrap h-min items-center relative py-12 text-center"
            }/>}
            })}
        </div>
    </section>
    

}

export default SolutionSection
