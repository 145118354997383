import React from "react";

const SectionHeader = ({Text, className}) =>{
    
    return <div className={className + " text-white h-16 text-center flex flex-col justify-center items-center  relative text-l text-bold uppercase"}>
        <span>{Text}</span>
    </div>
    
}

export default SectionHeader
