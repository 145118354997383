import React from "react";
import Tower from "../Images/BGTower.jpeg";
import   TMark from "../Images/Techsico_TMarkOnly_All.svg";

const EyeCatcher = () =>{
    return <section id="eye-catcher" className="w-full relative">
        <div className="relative md:h-[32rem] overflow-none">
            <img className="absolute h-full z-10 opacity-50"  src={TMark}/>
            <img className="relative object-cover object-[0,30%] top-0 h-full w-full"  src={Tower}/>
        </div>
        <span className="font-bold text-3xl text-white w-min sm:w-fit absolute text-right right-2 md:right-[64px] bottom-[48px] z-20">advancing communication.</span>
        <div className="w-full h-5 bg-tech-blue"></div>
    </section>
}

export default EyeCatcher