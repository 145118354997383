import React from "react"; 

const ContactDetails = ({Title, Content, className}) => {
    return<div className={className + "font-black"}>
        <span className="text-red-500 uppercase  ">{Title}</span>
        {Content.map((item, index )=> {
            if(typeof item === "string"){
                return <div key={index} >{item}</div>
            }
            if(item.isPhone){
                return <a key={index} href={"tel:"+item.text.replace(/-/g, "")} className="block font-bold hover:text-gray-500">{item.text}</a>
            }
            if(item.isEmail){
                return <a key={index} href={"mailto:"+item.text} className="block font-bold hover:text-gray-500">{item.text}</a>
            }
            return <div key={index} >{item.text}</div>
            })}
    </div>
}

export default ContactDetails