import React from "react";
import TPin from "../../Images/TPin.svg"
import classNames from "../utilities/classNames";

const LocationPin = ({Location ,Top, Left, index}) => {

    return <div className="absolute h-[9%] sm:h-[7%] aspect-square -translate-x-1/2 -translate-y-1/2 transition-transform group flex flex-col justify-center content-center z-0 hover:z-10 focus:outline-none" tabIndex="0" alt={Location} style={{top:Top, left:Left}}> 
        <span className="hidden absolute sm:group-hover:block sm:group-focus:block w-max h-min bottom-[110%] self-center bg-slate-100/90 rounded-md z-10 px-2 py-0 group-focus:outline-none whitespace-pre">{Location}</span>
        <img src={TPin} title={Location} className={"sm:group-hover:scale-125 sm:group-focus:scale-125 h-full w-full z-0"} ></img>
    </div>
}

export default LocationPin
