import React from "react";

const Paragraph = ({Content, className}) =>{
    return <div className={className}>
        {Content.Leadin && <span className="text-tech-red font-bold uppercase font-arial ">{Content.Leadin} </span>}
        {Content.Title && <span className="text-tech-red font-bold uppercase font-arial block md:inline-block">{Content.Title} </span>}
        <span className="body-font font-Arial">{Content.Body}</span>
    </div>
}

export default Paragraph