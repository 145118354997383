import React, {useEffect, useRef} from 'react';

const NavButton = ({link, text, className, toggleDropdown, onFocus}) =>{
    

    const onClick = () => {
        if(window.innerWidth < 768){
            toggleDropdown()
        }
    }
    return <a href={link} className={className} onClick={onClick} onFocus={ e => {e.preventDefault(); e.target.focus({preventScroll:true});}}>
        {text}
    </a>
}

export default NavButton