import React from "react";
import AboutDiv from "./AboutDiv";
import AboutJSON from "../../Content/AboutSection.json"
import TMark from "../../Images/Techsico_TMarkOnly_Grayscale.svg"

const AboutSection = () =>{
       
    return <section id="about" className="bg-white flex flex-col h-min justify-start items-center text-center relative md:p-4">
        
        <AboutDiv Data={AboutJSON.AboutParagraph}  />
        <AboutDiv Data={AboutJSON.ValuesParagraph} className="bg-slate-100 md:bg-inherit" />
        <AboutDiv Data={AboutJSON.MissionParagraph} />
        <img src={TMark} alt="" className=" select-none pointer-events-none z-10 absolute opacity-10 w-full sm:h-full sm:w-auto  top-0"></img>
    </section>
}

export default AboutSection