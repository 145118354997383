import React from "react";
import SectionHeader from "../SectionHeader";
import JobOpenings from "./jobOpenings/JobOpenings";
import techOnTower from "../../Images/BGTech.jpeg"

const CareerOpportunities = () =>{
    return <section id="careers" className="relative bg-gradient-to-b to-tech-blue from-[#3b80ee] h-fit sm:h-[30rem] md:h-[36rem]">
        <SectionHeader className="bg-tech-red z-10" Text="Career Opportunities"/>
        <div className="absolute top-16 left-0 w-full h-[28rem] md:h-[34rem] bg-blue-900 hidden sm:block ">
            <img src={techOnTower} className=" h-full w-full object-cover object-bottom "/>
            <div className="bg-gradient-radial from-[#001bef]/40 to-[#001bef]/50 bg-opacity-30 absolute top-0 left-0 w-full h-full text-white"></div>
        </div>
        
        <JobOpenings className="md:absolute relative  md:bg-transparent top-0 md:top-16 flex flex-col h-max justify-center items-center text-center text-white w-full  lg:h-[34rem] overflow-visible  md:overflow-hidden"/>
    </section>
}

export default CareerOpportunities