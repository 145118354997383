import './App.css';
import Header from './components/header/Header.js';
import EyeCatcher from './components/EyeCatcher.js';
import AboutSection from './components/aboutUs/AboutSection';
import SolutionSection from './components/solutions/SolutionsSection';
import CareerOpportunities from './components/careerOpportunities/CareerOpportunities';
import ContactSection from './components/contactSection/ContactSection';
import Footer from './components/Footer/Footer';
import SocialSection from './components/socials/SocialSection';



function App() {

   

  return (
    <article className=" flex flex-col min-h-screen justify-center items-center bg-gray-100  ">
      <section className="relative w-full lg:w-[1024px] font-Roboto ">
        <Header />  
        <EyeCatcher />
        <AboutSection  /> 
        <SolutionSection />
        <CareerOpportunities />
        <SocialSection isVisible={process.env.REACT_APP_SHOW_SOCIALS}/>
        <ContactSection />
        <Footer />
      </section>
    </article>
  );
}

export default App;
