import React from "react";
import SocialLink from "./SocialLink";
import SocialData from "../../Content/Socials.json"

const Socials = ({className}) =>{
    return <div className={className}>
        {SocialData.map((item, index) => <SocialLink key={index} Link={item.Link} Icon={item.Icon} className="hover:bg-slate-500 rounded-md"/>)}
    </div>
}

export default Socials