import React from "react";
import Socials from "./Socials";
import ContactInfo from "../../Content/ContactInfo.json"

const Footer = () =>{
    return<section id="footer" className="text-md">
        <div className="flex flex-row flex-wrap justify-around sm:justify-between align-center bg-tech-blue text-center text-white relative p-2 md:px-12 sm:h-24 gap-4">
            <div className="my-auto">
                <span>Connect with us</span>
                <Socials className="flex flex-row gap-2"/>
            </div>
            <div className="my-auto text-right">
                <div>{ContactInfo.businessDays.text} | {ContactInfo.businessHours.text}</div>
                <div>{ContactInfo.phone.text}</div>
            </div>
        </div>
        <div className="bg-tech-red text-white text-xs md:h-6 relative flex flex-wrap flex-row justify-around items-center text-center gap-x-4">
            <span className="">&copy; 2022 TEHCSICO, All rights reserverd.</span>
            <a href="https://www.techsico.com/privacypolicy" className="hover:text-gray-300">Privacy Policy</a>
        </div>
    </section>
}

export default Footer
