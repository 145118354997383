import React from 'react';
import NavButton from './navButtons/NavButton';
import Login from '../Login';
import classNames from '../../utilities/classNames';
import { useState, useEffect } from 'react';




const NavMenu = ({navItems, className, toggleDropdown, isshrunk}) =>{

    return <div id="navMenu" className={classNames(className)} >
        

        {navItems.NavMenu.map((item, index) => <NavButton toggleDropdown={toggleDropdown}  key={index} text={item.text} link={item.link} className="border-box text-red-500 font-bold px-4 h-5 md:border-red-500 md:border-r-2  md:[&:nth-child(4)]:border-r-0 hover:bg-slate-500/10" />)}  
         

        <Login Content={navItems.Login} className={classNames("order-first place-self-end !border-t-0 block relative md:absolute top-0 right-0 md:overflow-visible md:h-min md:transition-login  md:duration-500  md:text-right md:right-8 mx-4 md:mx-0 ", isshrunk ? "md:top-0 md:-right-36 md:opacity-0 hide": "md:top-8 md:opacity-100 md:text-black hover:text-slate-500")} />
    </div>
}


export default NavMenu