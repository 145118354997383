import React from "react";
import Job from "./Job";
import CareerData from "../../../Content/locations.json"

const JobOpenings = ({className}) =>{
    

    return <section id="jobs" className={className}>
        <span className="uppercase bold pb-12 pt-8 inline-block">Search job opening by location</span>
        <div className="flex md:flex-col flex-row flex-wrap md:h-[27rem] justify-center md:justify-start items-center place-content-center gap-y-8 md:gap-y-16 gap-x-12 md:gap-x-32 pb-12 md:pb-0">
            {CareerData.map(function(item, index ){ 
                if(item.link != ""){
                    return <Job key={index} location={item.location} URL={item.link}/>
                }})}
        </div>
    </section>
}

export default JobOpenings