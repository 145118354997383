import React from "react";
import Paragraph from "../aboutUs/Paragraph";


const Solution = ({imageName, altText, text,  className}) => {
    return <div className={className}>
        <img src={`/images/solutions/${imageName}`} alt={altText}></img>
        <div className="space-y-3 flex flex-col flex-wrap h-min items-center pt-3 ">
            {text.map((item, index) => <Paragraph key={index} Content={item} className="w-9/12 text-tech-gray"/>)}
        </div>
    </div>
}


export default Solution