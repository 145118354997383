import React from "react";

const TwitterPageEmbed = ({width, height}) =>{
    React.useEffect(() => {
        const script = document.createElement('script');
    
        script.src = "https://platform.twitter.com/widgets.js";
        script.async = true;
    
        document.body.appendChild(script);
    
        return () => {
          document.body.removeChild(script);
        }
      }, []);

    return <React.Fragment>
        <a className="twitter-timeline" data-width={width} data-height={height} href="https://twitter.com/Techsico_Inc?ref_src=twsrc%5Etfw">Tweets by Techsico_Inc</a>
    </React.Fragment>
}

export default TwitterPageEmbed