import React from "react";

const FacebookPageEmbed = React.memo(({width, height}) => {
    React.useEffect(() => {
        const script = document.createElement('script');
    
        script.src = "https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v9.0&appId=your-app-id&autoLogAppEvents=1";
        script.async = true;
    
        document.body.appendChild(script);
    
        return () => {
          document.body.removeChild(script);
        }
      }, []);
    
    return (
      <React.Fragment>
        <div className="fb-page" data-href="https://www.facebook.com/profile.php?id=100088572923367" data-tabs="timeline" data-width={width} data-height={height} data-small-header="true" data-adapt-container-width="true" data-hide-cover="false" data-show-facepile="true"></div>
          
      </React.Fragment>
    );
  });

  export default FacebookPageEmbed