import React from "react";
import TechsicoMap from "../../Images/USmap.svg"
import LocationData from "../../Content/locations.json"
import LocationPin from "./locationPin";
import classNames from "../utilities/classNames";
import { isDeclareModule } from "@babel/types";

const Map = ({className}) =>{
    function outputLocations (locationData){
        var line1 = []

        LocationData.map(function(item, index ){
            var item = <span key={index} className={classNames("relative  uppercase bg-tech-blue text-slate-100 rounded-md px-1 flex-grow ", item.order ? item.order : "basis-[48.5%]")}> {item.location}</span>;
                line1.push(item)
        })


        return (<React.Fragment>{line1}</React.Fragment>)
    }

    return<div className={className}>
        <div className="relative w-11/2 md:w-9/12">
            <img src={TechsicoMap} className="w-full h-full object-cover"/>
            {LocationData.map((item,index) => <LocationPin key={index}  Location={item.location} Top={item.position.top} Left={item.position.left}/>)}
        </div>
        <div className="flex flex-row flex-wrap h-min justify-between items-center text-center w-11/12 sm:hidden gap-x-2 gap-y-1 divide-x-2  pt-2 ">
            {outputLocations(LocationData)}
        </div>
    </div>
}

export default Map