import React from 'react';
import NavMenu from './nav/NavMenu';
import Title from './Title';
import { useState, useEffect } from 'react';
import navItems from "../../Content/NavSection.json";
import classNames from '../utilities/classNames';
import Hamburger from './nav/Hamburger';

const Header = () => {
    const [isShrunk, setShrunk] = useState(false);
    const [dropdownActive, setDropdownActive] = useState(false);

    useEffect(() => {
        const handler = () => {
            setShrunk((isShrunk) => {
                if (
                  !isShrunk &&
                  (document.body.scrollTop > 132 ||
                    document.documentElement.scrollTop > 132)
                ) {
                  return true;
                }
        
                if (
                  isShrunk &&
                  document.body.scrollTop < 92 &&
                  document.documentElement.scrollTop < 92
                ) {
                  return false;
                }
        
                return isShrunk;
              });
        };
    
        window.addEventListener("scroll", handler);
        return () => window.removeEventListener("scroll", handler);
      }, []);

    const toggleDropdown = () =>{
      setDropdownActive(!dropdownActive);
    }

    return <section className={classNames (' overflow-visible md:overflow-hidden w-[inherit] h-12 md:h-24 bg-white z-50')
    }>
        <div className={classNames("fixed top-0 w-[inherit] z-50 bg-white  transition-height duration-500 " , isShrunk ?"h-12 border-b-2 border-gray-200  " : " md:h-24")}>
          <Title className={classNames(('ml-4 mt-2 mb-2 md:mb-0 inline-block relative w-fit transition-all duration-500 h-fit'), isShrunk ? "md:mt-2" :"md:mt-5")} />

          <Hamburger toggleDropdown={toggleDropdown} className="inline-block md:hidden float-right mr-3 mt-2"/>

          <NavMenu toggleDropdown={toggleDropdown} navItems={navItems} isshrunk={isShrunk} className={classNames("overflow-hidden md:overflow-visible absolute md:relative  bg-slate-100 bg-opacity-70 backdrop-blur md:bg-inherit  md:inline-block w-full md:w-fit md:ml-auto float-right flex-col items-center md:flex-row md:flex-wrap md:h-fit md:opacity-100 md:mr-16 transition-all duration-500 md:pb-0 justify-end space-y-2 md:space-y-0 divide-tech-red divide-y-2 md:divide-y-0 z-0 pb-6", 
          isShrunk ? "md:mt-3":"mt-0 md:mt-6", 
          dropdownActive ? "flex h-40":"flex h-0 !pb-0  opacity-0")} />
        </div>
        
    </section>
}

export default Header